import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor() { }

  @Input() adminMode: boolean; // decorate the property with @Input()
  @Output() adminModeChange = new EventEmitter<boolean>();

  ngOnInit(): void {
  }

  toggleAdminMode(){
   this.adminMode=!this.adminMode;
   this.adminModeChange.emit(this.adminMode) 
  }
}
