<table class="table" width="100%">
  <tr *ngFor="let c of categories">
    <td>{{c.name}}</td>
    <td><i class="fa fa-arrow-up" (click)="categoryAction('up',c.id)"></i></td>
    <td><i class="fa fa-arrow-down" (click)="categoryAction('down',c.id)"></i></td>
    <td (click)="categoryAction('toggle',c.id)"><i class="fa fa-eye" *ngIf="!c.hidden"></i><i class="fa fa-eye-slash"
        *ngIf="c.hidden"></i></td>
    <td><i class="fa fa-trash" (click)="categoryAction('delete',c.id)"></i></td>
  </tr>
  <tr>
    <td colspan="5">&nbsp;</td>
  </tr>
  <tr>
    <td colspan="4"><input class="input" type="text" [(ngModel)]="newCategory" placeholder="Aggiungi Categoria"></td>
    <td><button class="button is-dark"><i class="fa fa-save" (click)="addCategory()"></i></button></td>
  </tr>

</table>

<div class="modal" class="modal" [ngClass]="{ 'is-active' : displayModal }">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Attenzione</p>
      <button class="delete" aria-label="close" (click)="toggleModal()"></button>
    </header>
    <section class="modal-card-body">
    {{message}}
    </section>
  </div>
</div>
