import { Component, OnInit, Input } from '@angular/core';
import { RailsService } from '../rails.service';
import { UtilsService } from '../utils.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.css'],
})
export class ToolsComponent implements OnInit {
  qrs = [0, 0, 0, 0];
  urls = ['' , '' , '' , ''];
  nativeWindow: any;

  @Input() sessionId: string;
  @Input() restaurantData: any;
  constructor(private rails: RailsService, private utils: UtilsService) {}

  ngOnInit(): void {
    this.nativeWindow = this.utils.getNativeWindow();
  }

  generateQr(): void {
    this.rails.generateQr(this.sessionId, this.qrs).subscribe((result) => {
      console.log('OK');
    });
  }

  generateUrls(): boolean {
    for (let i = 0; i < 4; i++) {
       this.urls[i] = '/?t=' + this.utils.encodeParams({restaurant: this.restaurantData.restaurant.id, table: this.qrs[i]});
       console.log(this.utils.encodeParams({restaurant: this.restaurantData.restaurant.id , table: this.qrs[i]}));
    }
    this.nativeWindow.document.forms.qrs.urls.value = this.urls;
    return true;
  }
}
