import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import * as basex from 'base-x';
import { table } from 'console';
import { isObject } from 'util';

function _window(): any {
  // return the global native browser window object
  return window;
}


@Injectable({
  providedIn: 'root',
})


export class UtilsService {
  // constructor( private bc: BaseConverter) { }
  constructor() {}

  getNativeWindow(): any {
     return _window();
  }

  testEncryption() {
    const BASE58 = '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz';
    var bc = basex(BASE58);
    var enb = bc.encode(
      this.strToByteArray(JSON.stringify({ restaurant: 1, table: 23 }))
    );
    console.log('Encryption test ' + enb);
    var en = bc.decode(enb);
    console.log('Encryption test ' + en);
  }

  decodeParams(s: string):number{
    const BASE58 = '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz';
    var bc = basex(BASE58);
    var en = bc.decode(s).toString();
    console.log("Decoded "+en);
    var ob=JSON.parse(en);
    return ob.restaurant*1000+ob.table;
  }

  encodeParams(a:any): string {
    const BASE58 = '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz';
    let bc = basex(BASE58);
    let enb = bc.encode(
      this.strToByteArray(JSON.stringify(a))
    );
    return enb;
  }

  strToByteArray(s) {
    var res = [];
    for (var i = 0; i < s.length; i++) {
      res.push(s.charCodeAt(i));
    }
    return res;
  }
}
