<div class="is-flex is-flex-wrap-wrap">
  <button class="button is-small is-dark" [ngClass]="category==c.id? '' : 'is-outlined'"
    *ngFor="let c of categories ; let i = index" [attr.data-index]="i" (click)="this.category=c.id">{{c.name}}</button>
</div>
<table class="table" width="100%" [hidden]="editing!==0">
  <tr *ngFor="let d of dishes; let j = index" [attr.data-index]="j">
    <td *ngIf="d.category_id==category">{{d.name}}</td>
    <td *ngIf="d.category_id==category" (click)="loadCurDish(d); this.editing=this.editing==0 ? d.id : 0"><i
        class="fa fa-edit"></i></td>
    <td *ngIf="d.category_id==category" (click)="dishAction('up',d.id)"><i class="fa fa-arrow-up"></i></td>
    <td *ngIf="d.category_id==category" (click)="dishAction('down',d.id)"><i class="fa fa-arrow-down"></i></td>

    <td *ngIf="d.category_id==category" (click)="dishAction('toggle',d.id)"><i class="fa fa-eye" *ngIf="!d.hidden"></i>
      <i class="fa fa-eye-slash" *ngIf="d.hidden"></i></td>
    <td *ngIf="d.category_id==category" (click)="this.confirmDelete=d.id"><i class="fa fa-trash"></i>
    </td>
  </tr>

  <tr>
    <td colspan="6">&nbsp;</td>
  </tr>
  <tr>
    <td colspan="5"><input class="input" type="text" [(ngModel)]="newDish" placeholder="Aggiungi Piatto">
    </td>
    <td><button class="button is-dark"><i class="fa fa-save" (click)="addDish()"></i></button></td>
  </tr>
</table>

<div class="container" *ngIf="editing!==0" class="dishEdit">
  <input class="input" type="text" [(ngModel)]="curDish.name" placeholder="Nome del Piatto">
  <input class="input" type="text" [(ngModel)]="curDish.description" placeholder="Descrizione del Piatto">
  <input class="input" type="text" [(ngModel)]="curDish.allergens" placeholder="Allergeni">
  <input class="input" type="number" step=".50" [(ngModel)]="curDish.price" placeholder="Prezzo"
    style="margin-bottom:10px">
  <div class="form-group" *ngIf="curDish.image==''">
    <label for="file" class="button is-small is-dark is-outline"><i class="fa fa-image"></i>&nbsp; Scegli
      immagine</label>
    <input class="button is-light" type="file" class="inputfile" id="file"
      (change)="handleFileInput($event.target.files)">
  </div>
  <div *ngIf="curDish.image!==''" style="display: block; float:none">
    <div style="float:left">
      <figure class="image is-128x128 dishimage">
        <img src="/images/{{curDish.image}}">
      </figure>
      <!--  {{curDish.image}} -->
    </div>
    <div class="deleteImage" style="float:left; " (click)="dishAction('deleteimage',this.editing); loadDishes(); this.editing=0">
      <i class="fa fa-trash button is-dark"></i>
    </div>
  </div>
  <div style="margin: 10px; clear: both;">
    <div class="button is-dark is-small" (click)="modifyDish();"><i class="fa fa-save"></i>&nbsp;Salva</div>
    &nbsp;
    <div class="button is-dark is-small"
      (click)="this.curDish = {name:'', description:'', allergens:'', price: 0, image: ''};this.editing=0"><i
        class="fa fa-window-close"></i>&nbsp;Annulla
    </div>
  </div>
</div>

<div class="modal" [ngClass]="{ 'is-active' : displayModal }">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Attenzione</p>
      <button class="delete" aria-label="close" (click)="toggleModal()"></button>
    </header>
    <section class="modal-card-body">
      {{message}}
    </section>
  </div>
</div>

<div class="modal" [ngClass]="{ 'is-active' : confirmDelete!==0 }">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Attenzione</p>
      <button class="delete" aria-label="close" (click)="this.confirmDelete=0"></button>
    </header>
    <section class="modal-card-body">
      Confermi cancellazione ?
    </section>
    <section class="modal-card-footer">
      <div class="button is-light is-small" (click)="dishAction('delete',this.confirmDelete); this.confirmDelete=0"><i
          class="fa fa-save"></i>&nbsp;Conferma</div>
      &nbsp;
      <div class="button is-light is-small" (click)="this.confirmDelete=0"><i
          class="fa fa-window-close"></i>&nbsp;Annulla
      </div>
    </section>
  </div>
</div>