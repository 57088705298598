import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { RailsService } from '../rails.service';
registerLocaleData(localeIt, 'it');

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit, AfterViewInit {
 todayDate : Date = new Date();
 sections: boolean[];
 details: boolean[][];
 showDish: boolean = false;
 currentDishImage: string = "/images/placeholder.png";
 images = [];
 restaurantIcon = '';

 @Input() restaurantData: any; // decorate the property with @Input()
  constructor( private rails: RailsService ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.restaurantIcon = this.getRestaurantIcon();
    console.log('>>>' + this.getRestaurantIcon());
  }

  updateArrays(a: any): void {
    this.details=[[]];
    this.sections=Array(a.categories.length).fill(false);
    a.categories.forEach((element) => {
     this.details.push(Array(element.dishes.length).fill(false)); // riempie le visibilità dei piatti 
    });
    this.images = a.images;
    this.getRestaurantIcon();
     //this.details=Array(a.categories.length).fill(false);
  }

  toggleDishImage() {
    this.showDish=! this.showDish;
  }

  loadDishImage(i:string) {
    this.currentDishImage=i;
  }

  getRestaurantIcon(): string {
    console.log(this.images);
    const img = this.images.find(
      (e) => e.imagetype === 0
    );
    if (img) {
      this.restaurantIcon = img.url;
      return img.url;
    } else {
      this.restaurantIcon = '';
      return '';
    }
  }



}
