import { Component, OnInit, Input } from '@angular/core';
import { RailsService } from '../rails.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css'],
})
export class CategoriesComponent implements OnInit {
  message: string = '';
  newCategory: string = '';
  displayModal: boolean = false;
  categories: any[] = [];

  @Input() sessionId: string;
  constructor(private rails: RailsService) {}

  ngOnInit(): void {}

  loadCategories() {
    this.message = 'loading';
    this.rails.loadCategories(this.sessionId).subscribe((result) => {
      this.categories = result.categories;
    });
  }

  categoryAction(a: string, n: number) {
    this.rails.categoryAction(this.sessionId, a, n).subscribe((result) => {
      this.message = JSON.stringify(result);
      if (result.code == 0){
      this.categories = result.categories;
      }
      else
      {
        this.message=result.message;
        this.toggleModal();
      }
    });
  }

  addCategory() {
    this.rails
      .addCategory(this.sessionId, this.newCategory)
      .subscribe((result) => {
        if (result.code == 0) {
          this.categories = result.categories;
          this.newCategory = '';
        } else {
          this.message = result.message;
          this.newCategory = '';
          this.displayModal = true;
        }
      });
  }

  toggleModal() {
    this.displayModal=!this.displayModal;
  }

}
