import { Component, AfterViewInit, Inject, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { RailsService } from './rails.service';
import { UtilsService } from './utils.service';
import { MenuComponent } from './menu/menu.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements AfterViewInit{
 @ViewChild(MenuComponent) child:MenuComponent;
  title = 'Tavolo Uno';
  rid: number;
  restaurantData: any;
  restaurantId: string;
  adminMode: boolean = false;


  constructor(@Inject(DOCUMENT) private document: Document, private rails: RailsService, private utils: UtilsService, private router: Router) {
  }

  ngOnInit() { 
    this.rid=0;
    this.restaurantData={}
    const browserUrl=this.document.location.href.split('=');
    this.restaurantId = browserUrl[browserUrl.length - 1 ];
    this.utils.decodeParams(this.restaurantId);
    localStorage.setItem('restaurantId', this.restaurantId);
    //this.getRestaurantData(this.restaurantId);
    this.getRestaurantData(this.utils.decodeParams(this.restaurantId)+'');
    //console.log(this.restaurantId);
    console.log(this.utils.decodeParams(this.restaurantId));
    this.utils.testEncryption();
     
  }

  getRestaurantData(id:string):any {
    this.rails.getRestaurantData(id).subscribe(
      (result) => {
        this.restaurantData=result;
        this.child.updateArrays(result);
        console.log(result);
      }, 
      (error) => {
        console.log(error);
        this.restaurantData={};
      }
    )
    this.restaurantData={restaurant:{name: "", image: ""},categories:[]}
  }

  ngAfterViewInit() {
  }

  navigateTo(u: string) {
   this.router.navigateByUrl(u);
  }

}
