<div class="box">
    <form method="POST"  name="qrs" action="/api/generateqr" target="_new" >
        <input type="hidden" name="session" value="{{sessionId}}">
        <input type="hidden" name="urls" >
        <div class="tile is-ancestor is-dark">
            <div class="tile is-vertical is-dark">
                <button  class="button is-dark" (click)="generateUrls(); this.nativeWindow.document.forms['qrs'].submit()">
                    <h2>
                        <i class="fa fa-qrcode fa-2x"></i> &nbsp;Genera QR -
                        indica i tavoli per cui stampare i QR</h2>
                    </button>
                <div class="tile ">
                    <div class="tile is-horizontal is-3">
                        <input class="input" name="qrs0" [(ngModel)]="qrs[0]" type="number" placeholder="numero" standalone>
                    </div>
                    <div class="tile is-horizontal is-3">
                        <input class="input" name="qrs1" [(ngModel)]="qrs[1]" type="number" placeholder="numero" standalone>
                    </div>
                    <div class="tile is-horizontal is-3">
                        <input class="input" name="qrs2" [(ngModel)]="qrs[2]" type="number" placeholder="numero" standalone>
                    </div>
                    <div class="tile is-horizontal is-3">
                        <input class="input" name="qrs3" [(ngModel)]="qrs[3]" type="number" placeholder="numero" standalone>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>