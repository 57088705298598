<div id="login" *ngIf="sessionId!==''" class="is-block">
  <div class="columns is-flex-mobile is-paddingless deselezionato">
    <div
      [className]="tab==1 ? 'selezionato column is-auto has-text-centered' : 'deselezionato column is-auto has-text-centered'"
      (click)="tab=1">
      Dati
    </div>
    <div
      [className]="tab==2 ? 'selezionato column is-auto has-text-centered' : 'deselezionato column is-auto has-text-centered'"
      (click)="tab=2; this.child.loadCategories()">
      Categorie
    </div>
    <div
      [className]="tab==3 ? 'selezionato column is-auto has-text-centered' : 'deselezionato column is-auto has-text-centered'"
      (click)="tab=3;this.child_d.loadCategoriesAndDishes()">
      Piatti
    </div>
    <div
      [className]="tab==4 ? 'selezionato column is-auto has-text-centered' : 'deselezionato column is-auto has-text-centered'"
      (click)="tab=4">
      Strumenti
    </div>
  </div>
  <app-categories [sessionId]="sessionId" [hidden]="tab!==2"></app-categories>
  <app-dishes [sessionId]="sessionId" [hidden]="tab!==3"></app-dishes>
  <app-tools [restaurantData]="restaurantData" [sessionId]="sessionId" [hidden]="tab!==4"></app-tools>
  <app-general [generalData]="{sessionId:sessionId, restaurantData: restaurantData}"  [hidden]="tab!==1"></app-general>

</div>



<div id="login" *ngIf="sessionId==''">
  <div class="login-card">

    <div class="card-title">
      <h1>Accedi all'area riservata</h1>
    </div>

    <div class="content">
      <form method="POST" action="#">

        <input id="email" [(ngModel)]="login" type="text" name="login" title="login" placeholder="Login" required
          autofocus>
        <input id="password" [(ngModel)]="password" type="password" name="password" title="password"
          placeholder="Password" required>

        <div class="level options">
          <div class="checkbox level-left">
            <input type="checkbox" id="checkbox" class="regular-checkbox">
            <!--<label for="checkbox"></label>
            <span>Remember me</span> -->
          </div>
          <div class="warning">{{ message }}{{sessionId}}</div>
          <!-- <a class="btn btn-link level-right" href="#">Forgot Password?</a> -->
        </div>

        <button type="submit" class="btn btn-primary" (click)="authenticateUser()">Login</button>
      </form>
    </div>
  </div>
</div>
