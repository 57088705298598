import { Component, OnInit, Input } from '@angular/core';
import { RailsService } from '../rails.service';
import { Dish } from '../dish';

@Component({
  selector: 'app-dishes',
  templateUrl: './dishes.component.html',
  styleUrls: ['./dishes.component.css']
})
export class DishesComponent implements OnInit {
  message = '';
  categories: any[] = [];
  category = 0;
  displayModal = false;
  confirmDelete = 0;
  dishes: any[] = [];
  newDish = '';
  method1: any;
  method2: any;
  editing = 0;
  curDish: Dish = {name: '', description: '', allergens: '', price: 0, image: ''};
  debug = '';
  fileToUpload: File = null;

@Input() sessionId: string;
  constructor( private rails: RailsService ) { }

  ngOnInit(): void {
  }

  loadCategories() {
    this.message = 'loading';
    this.rails.loadCategories(this.sessionId).subscribe((result: { categories: any[]; }) => {
      this.categories = result.categories;
      this.category = this.category[0].id;
    });
  }

  loadDishes(){
    this.editing = 0;
    this.message = 'loading';
    this.rails.loadDishes(this.sessionId).subscribe((result: { dishes: any[]; }) => {
      this.dishes = result.dishes;
    });
  }


  loadCategoriesAndDishes(){
    this.loadCategories();
    this.loadDishes();
  }

  dishAction(a: string, n: number) {
    this.rails.dishAction(this.sessionId, a, n).subscribe((result) => {
      this.message = JSON.stringify(result);
      if (result.code === 0){
      this.dishes = result.dishes;
      }
      else
      {
        this.message = result.message;
        this.toggleModal();
      }
    });
  }

  toggleModal(){
    this.displayModal = !this.displayModal;
  }

  addDish(){
    this.rails
      .addDish(this.sessionId, this.category, this.newDish)
      .subscribe((result) => {
        if (result.code === 0) {
          this.dishes = result.dishes;
          this.newDish = '';
        } else {
          this.message = result.message;
          this.newDish = '';
          this.displayModal = true;
        }
      });
  }

  modifyDish(){
    console.log('Preparing to modify');
    this.rails
      .modifyDish(this.sessionId, this.editing, this.curDish)
      .subscribe((result) => {
        if (result.code === 0) {
          this.dishes = result.dishes;
          this.curDish = {name: '', description: '', allergens: '', price: 0, image: ''};
          this.editing = 0;
        } else {
          this.message = result.message;
          this.curDish = {name: '', description: '', allergens: '', price: 0, image: ''};
          this.displayModal = true;
        }
      });
  }


  loadCurDish(d: any){
    this.curDish.name = d.name;
    this.curDish.description = d.description;
    this.curDish.allergens = d.allergens;
    this.curDish.price = d.price;
    this.curDish.image = d.image;
  }


  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    this.uploadFileToActivity();
  }

  uploadFileToActivity() {
    this.rails.postFile(this.fileToUpload, this.sessionId, this.editing).subscribe(data => {
      this.loadDishes();
      // do something, if upload success
      }, error => {
        console.log(error);
      });
  }


  zodifyDish(): any {
    console.log(Date.now() + '');
    this.debug = Date.now() + '';
    return 0;
  }

  filterDishesByCategory(c: number): any[]{
    const filtered = this.dishes.filter(function(e){ return e.category === c; });
    console.log('Filtered=' + JSON.stringify(filtered));
    return filtered;
  }


}
