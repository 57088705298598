    <div class="dicafooter" *ngIf="adminMode">
     <div class="button is-dark" onClick="window.location.reload()">Esci dal modo admin</div> 
    </div>

    <div class="dicafooter" (dblclick)="toggleAdminMode()">
        <!-- <object type="image/svg+xml" data="/assets/dica2.svg"  width="15%" style="float:left" >
            Your browser does not support SVG
          </object> -->
        <figure class="image" style="display:inline-block; margin:20px; width:48px">
          <img src="/assets/tavolo1.png">
        </figure>
        <div style="float:right; margin: 20px">&copy;2024 Tavolo Uno</div>
        <div style="float:right; margin: 20px">v.1.0.1</div>
        
    </div>
