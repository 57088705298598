import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { RailsService } from '../rails.service';
import { CategoriesComponent } from '../categories/categories.component'
import { DishesComponent } from '../dishes/dishes.component'

@Component({
  selector: 'app-backend',
  templateUrl: './backend.component.html',
  styleUrls: ['./backend.component.css']
})
export class BackendComponent implements OnInit {
 @ViewChild(CategoriesComponent) child:CategoriesComponent;
 @ViewChild(DishesComponent) child_d:CategoriesComponent;
  isLogged: boolean=false;
  sessionId: string="";
  message: string="";
  login: string="";
  password: string="";
  tab: number=1;

@Input() restaurantData: any;
  constructor( private rails: RailsService ) { }

  ngOnInit(): void {
    this.tab=1;
  }

  authenticateUser():any{
    this.rails.authenticate(this.login,this.password).subscribe(
      (result: { code: number; message: string; session: string; })=> {
        if (result.code !== 0) {
         this.message=result.message;
        }
        else
        {
         this.message="";
         this.sessionId=result.session;
        }
      }, (error: any) => { this.message="Credenziali errate";}
    )
  }


  alert(s) {
    window.alert(s);
  }

}
