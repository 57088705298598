import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Md5 } from 'ts-md5/dist/md5';
import { Dish } from './dish';

@Injectable({
  providedIn: 'root',
})
export class RailsService {
  constructor(private http: HttpClient) {}

  getRestaurantData(id: string): Observable<any> {
    return this.http.get<any>('/api/getrestaurantdata/' + id);
  }

  authenticate(l: string, p: string): any {
    const md5 = new Md5();
    const h = md5.appendStr(p).end();
    return this.http.post<any>('/api/authenticate', { login: l, password: h });
  }

  loadCategories(s: string): any {
    return this.http.post<any>('/api/getcategories', { session: s });
  }

  loadDishes(s: string): any {
    return this.http.post<any>('/api/getdishes', { session: s });
  }

  categoryAction(s: string, a: string, n: number): any {
    return this.http.post<any>('/api/categoryaction', {
      session: s,
      op: a,
      id: n,
    });
  }

  dishAction(s: string, a: string, n: number): any {
    return this.http.post<any>('/api/dishaction', { session: s, op: a, id: n });
  }

  addCategory(s: string, c: string): any {
    return this.http.post<any>('/api/addcategory', { session: s, name: c });
  }

  addDish(s: string, c: number, d: string): any {
    return this.http.post<any>('/api/adddish', {
      session: s,
      category: c,
      name: d,
    });
  }

  modifyDish(s: string, n: number, d: Dish): any {
    console.log('Modifying dish...');
    return this.http.post<any>('/api/modifydish', {
      session: s,
      id: n,
      dish: d,
    });
  }

  postFile(fileToUpload: File, s: string, id: number): Observable<boolean> {
    const endpoint = '/api/uploaddishimage';
    const formData: FormData = new FormData();
    formData.append('session', s);
    formData.append('id', id + '');
    formData.append('fileKey', fileToUpload, fileToUpload.name);
    return this.http
      .post(endpoint, formData, { headers: {} })
      .pipe(map(() => true));
  }

  generateQr(s: string, t: number[]): any {
    console.log('Modifying dish...');
    return this.http.post<any>('/api/generateqr', {
      session: s,
      tables: t,
    });
  }


  saveRestaurantName(g: any): any {
    return this.http.post<any>('/api/saverestaurantname', { session: g.sessionId, name: g.restaurantData.restaurant.name });
  }


  deleteRestaurantImage(g: any): any {
    return this.http.post<any>('/api/deleterestaurantimage', { session: g.sessionId });
  }

  postRestaurantImage(fileToUpload: File, g: any): Observable<any> {
    const endpoint = '/api/postrestaurantimage';
    const formData: FormData = new FormData();
    formData.append('session', g.sessionId);
    formData.append('fileKey', fileToUpload, fileToUpload.name);
    return this.http
      .post(endpoint, formData, { headers: {} })
      .pipe(map((result) => result));
  }


}
