<div class="box">
    <p class="is-size-5 is-semibold" *ngIf="!nameEdit">
        {{generalData.restaurantData.restaurant.name}} &nbsp;
        <button class="fa fa-edit button is-dark is-small" (click)="this.nameEdit=!this.nameEdit"></button> &nbsp;
    </p>
    <p class="is-size-5 is-semibold" *ngIf="nameEdit">
        <input class="input" type="text" [(ngModel)]="generalData.restaurantData.restaurant.name"
            placeholder="Inserisci nome">
        <button class="fa fa-save" (click)="saveRestaurantName();"></button> &nbsp;
        <button class="fa fa-window-close" (click)="this.nameEdit=!this.nameEdit"></button> &nbsp;
    </p>
</div>

<div class="box" >
    <table class="is-table">
        <tr *ngIf="getRestaurantIcon()!==''">
            <td>
                <figure class="image is-128x128 dishimage">
                    <img src="/images/{{getRestaurantIcon()}}">
                </figure>

            </td>
            <td>
                &nbsp;
                <i class="fa fa-trash button is-dark" (click)="this.deleteRestaurantImage()"></i>
            </td>
        </tr>
        <tr *ngIf="getRestaurantIcon()==''">
            <td>
               <label for="file" class="button is-small is-dark is-outline"><i class="fa fa-image"></i>&nbsp; Scegli
               immagine</label>
            </td>
            <td>
               <input class="button is-light" type="file" class="inputfile" id="file" (change)="handleFileInput($event.target.files)">

            </td>
        </tr>
    </table>
</div>


<div class="box">
    <p class="is-size-5">
        Tavolo Uno
    </p>
    <p class="is-size-6">
        versione 0.99.1
    </p>
    <p class="is-size-7">
        licenza test illimitata
    </p>
</div>