<section class="hero is-dark is-small">
  <div class="hero-body">
    <table class="is-table" width="100%">
      <tr>
        <td  style="vertical-align:middle" >
          <figure class="image is-64x64">
            <img class="is-rounded" src="/images/{{restaurantIcon}}">
          </figure>
        </td>
        <td>
          <h1 class="title" style="text-align: center">
            {{ restaurantData.restaurant.name }}
          </h1>
          <h2 class="subtitle" style="text-align: center">
            {{ todayDate | date: "fullDate":"+0200":"it" | titlecase }}
          </h2>

        </td>
      </tr>
    </table>
  </div>
</section>

<div class="container" *ngFor="let c of restaurantData.categories; let i = index" [attr.data-index]="i">
  <section *ngIf="!c.hidden" style="padding-bottom:5px" class="has-background-grey-dark has-text-white is-small has-text-weight-semibold"
    (click)="sections[i]=!sections[i]">
    <p class="is-text-bold is-size-5">
      &nbsp;
      <i class="fa fa-caret-right" aria-hidden="true" *ngIf="!sections[i]"> </i>
      <i class="fa fa-caret-down" aria-hidden="true" *ngIf="sections[i]"> </i>
      &nbsp;
      <fa name="caret-right"></fa>
      {{ c.name }}</p>
  </section>
  <div style="width:100%; height:2px" *ngIf="!sections[i]">&nbsp;</div>
  <div class="container" *ngIf="sections[i]">
    <table class="table" width="100%">
      <tr *ngFor="let d of c.dishes; let j = index" [attr.data-index]="j">
        <td colspan="3" width="100%" *ngIf="!d.price || d.price==0" class="subcategory"><b><span
              class="subcategory is-text-bold is-size-5">{{ d.name }}</span></b></td>
        <td *ngIf="d.price && d.price!==0 && !d.hidden" width="80%" (click)="details[i][j]=!details[i][j]"><b><span
              class="is-size-5">{{ d.name }}</span></b>
          <div *ngIf="details[i][j]*sections[i]">

            {{ d.description }}
            <span class="is-size-7" *ngIf="d.allergens!=''"><br />Allergeni: {{ d.allergens }}</span>
          </div>
        </td>

        <td *ngIf="d.price && d.price!==0 && !d.hidden" width="10%"><i class="fa fa-image fa-2x" aria-hidden="true" *ngIf="d.image"
            (click)="loadDishImage(d.image);toggleDishImage()"> </i></td>
        <td *ngIf="d.price && d.price!==0 && !d.hidden" width="10%"><b><span
              class="is-size-5">{{ d.price | currency:'EUR':'symbol':'1.2-2'}}</span></b></td>
      </tr>
      <tr>
      </tr>
    </table>
  </div>
</div>

<div class="modal" [ngClass]="{ 'is-active' : showDish }">
  <div class="modal-background"></div>
  <div class="modal-content">
    <p class="image is-4by3">
      <img src="/images/{{currentDishImage}}" alt="">
    </p>
  </div>
  <button class="modal-close is-large" aria-label="close" (click)="toggleDishImage()"></button>
</div>