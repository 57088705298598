import { Component, OnInit, Input } from '@angular/core';
import { RailsService } from '../rails.service';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.css'],
})
export class GeneralComponent implements OnInit {
  nameEdit = false;
  restaurantIcon = '';
  fileToUpload: File = null;

  @Input() generalData: any;
  constructor(private rails: RailsService) {}

  ngOnInit(): void {}

  saveRestaurantName(): void {
    this.rails.saveRestaurantName(this.generalData).subscribe((result) => {
      if (result.code === 0) {
        console.log('Name changed');
        this.nameEdit = false;
      }
    });
  }

  getRestaurantIcon(): string {
    const img = this.generalData.restaurantData.images.find(
      (e) => e.imagetype === 0
    );
    if (img) {
      return img.url;
    } else {
      return '';
    }
  }

  deleteRestaurantImage(): void {
    this.rails.deleteRestaurantImage(this.generalData).subscribe((result) => {
      this.generalData.restaurantData.images = result.images;
    });
  }


  handleFileInput(files: FileList): void {
    this.fileToUpload = files.item(0);
    this.uploadFileToActivity();
  }

  uploadFileToActivity(): void {
    this.rails.postRestaurantImage(this.fileToUpload, this.generalData).subscribe((result) => {
      this.generalData.restaurantData.images = result.images;
      }, error => {
        console.log(error);
      });
  }



}
